import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { ServicePreferencesDialogViewModel } from '../../viewModel/dialogViewModel/dialogViewModel';
import { AsyncComponentDataStatus } from '@wix/bookings-services-preferences-modal/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isServiceDynamicPricing } from '../../utils/serviceDetails/serviceDetails';

export type OnPreferencesModalServiceSelected = (
  selectedService: Service,
) => void;

export const createOnPreferencesModalServiceSelectedAction = ({
  widgetViewModel,
  setProps,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}): OnPreferencesModalServiceSelected => {
  return async (selectedService) => {
    const { onServiceSelected } = await import(
      '@wix/bookings-services-preferences-modal/utils'
    );
    const dialogViewModel =
      widgetViewModel.dialogViewModel as ServicePreferencesDialogViewModel;
    dialogViewModel.data.inView.serviceOptionsAndVariants = {
      status: isServiceDynamicPricing(selectedService)
        ? AsyncComponentDataStatus.LOADING
        : AsyncComponentDataStatus.IDLE,
      data: undefined,
    };
    dialogViewModel.data.inView.service = selectedService;
    dialogViewModel.data.inView.preselectedValues = undefined;

    setProps({
      widgetViewModel: { ...widgetViewModel },
    });

    onServiceSelected({
      selectedService,
      httpClient: flowAPI.httpClient,
      instance: flowAPI.controllerConfig.appParams.instance,
      updateProps: ({
        additionalServiceInfoStatus,
        serviceInView,
        serviceOptionsAndVariants,
      }) => {
        const modalData = dialogViewModel.data;

        if (additionalServiceInfoStatus) {
          modalData.inView.additionalServiceInfoStatus =
            additionalServiceInfoStatus;
        }

        if (serviceInView) {
          modalData.inView.service = serviceInView;
        }

        if (serviceOptionsAndVariants) {
          modalData.inView.serviceOptionsAndVariants =
            serviceOptionsAndVariants;
        }

        setProps({
          widgetViewModel: { ...widgetViewModel },
        });
      },
    });
  };
};
